<template>
	<div class="account-content">

        <template v-if="loggedIn">
            
        
		    <div class="loader" v-if="loaded==false"><img src="../../public/assets/images/2021/05/loader.gif"></div>
	
		  

		<div id="account-content" v-if="loaded && order">
			<h2 v-if="language=='fr'">Suivi de la commande <span id="order-number">Confirmée le {{order.date}}</span></h2>
      <h2 v-else>Your order <span id="order-number">Confirmed {{order.date}}</span></h2>
			<div id="order-details">
        <div class="steps">
          <div class="step" :class="{active:order.status=='completed' || order.status==null || order.status=='scanned' || order.status=='dispatched' || order.status=='printing' || order.status=='delivered'}">
              <div class="image">
                  <div class="round"><img src="../../public/assets/images/check.png" width="8" /></div>
              </div>
              <div class="step-name" v-if="language=='fr'">
                  Commande est enregistrée
              </div>
              <div class="step-name" v-else>
                  Your order is confirmed
              </div>
          </div>
          <div class="step" v-if="hasOnMeasure==true" :class="{active:order.status=='scanned'  || order.status==null || order.status=='dispatched' || order.status=='printing' || order.status=='delivered'}">
              <div class="image">
                  <div class="round"><img src="../../public/assets/images/check.png" width="8" /></div>
              </div>
              <div class="step-name" v-if="language=='fr'">
                  <span v-if="order.status=='scanned'  || order.status==null || order.status=='dispatched' || order.status=='printing' || order.status=='delivered'">Scan reçu</span>
                  <span v-else>En attente de scan</span>
              </div>
              <div class="step-name" v-else>
                <span v-if="order.status=='scanned'  || order.status==null || order.status=='dispatched' || order.status=='printing' || order.status=='delivered'">Scan received</span>
                <span v-else>Waiting scan</span>
                
              </div>
              
          </div>
          <div class="step" :class="{active:order.status=='scanned' || order.status=='dispatched' || order.status=='printing' || order.status=='delivered'}">
              <div class="image">
                  <div class="round"><img src="../../public/assets/images/check.png" width="8" /></div>
              </div>

              <div class="step-name" v-if="language=='fr'">
                  Production lancée
              </div>
              <div class="step-name" v-else>
                  In production
              </div>
              
          </div>

          <div class="step" :class="{active:order.status=='dispatched' || order.status=='delivered'}">
              <div class="image">
                  <div class="round"><img src="../../public/assets/images/check.png" width="8" /></div>
              </div>

              <div class="step-name" v-if="language=='fr'">
                  Commande expédiée
              </div>
              <div class="step-name" v-else>
                  Dispatched
              </div>
              
          </div>

          <div class="step" :class="{active:order.status=='delivered'}">
              <div class="image">
                  <div class="round"><img src="../../public/assets/images/check.png" width="8" /></div>
              </div>

              <div class="step-name" v-if="language=='fr'">
                  Commande livrée
              </div>
              <div class="step-name" v-else>
                  Delivered
              </div>
              
          </div>
          
      </div>
      <div class="row">
        <div class="left">

          <div v-if="tracking_number!=null">
              <br /><br />
              <a  v-if="language=='fr'" :href="'https://www.laposte.fr/outils/suivre-vos-envois?code='+tracking_number">Suivre la commande</a>
              <a  v-else :href="'https://www.laposte.fr/outils/suivre-vos-envois?code='+tracking_number">Order tracking</a>
              <br /><br />
          </div>

          <div class="details">
            <h2 v-if="language=='fr'">Détails de la commande</h2>
            <h2 v-else>Order details</h2>
            <b v-if="language=='fr'">Coordonnées</b>
            <b v-else>Contact</b>
            
            <br />
            {{ order.user.name }} {{ order.user.surname }}
            <br />
            {{ order.user.email }}

            <div v-if="order.address!=null">
                <br /><br />
                <b v-if="language=='fr'">Adresse de livraison : </b>
                <b v-else>Delivery address : </b>
                <br />
                {{order.address.address1}}<br />{{order.address.postcode}}<br />{{order.address.city}}<br />{{order.address.country}}
            
              </div>
              <br /><br />

              <b v-if="language=='fr'">Mode d'expédition</b>
              <b v-else>Dispatch mode</b>
              <br />

              <span v-if="language=='fr'">Livraison standard</span>
              <span v-else>Standard delivery</span>
              <br /><br />
          </div>

        </div>
      
   

			      
       
          <CheckoutOrderReview v-if="order"
            :language="language"
            :loaded="loaded"
            :cart_items="order.order_items"
            :photos="photos"
            :promotionError="promotionError"
            :displayPromotion="displayPromotion"
            :total="order.amount"
            :totalOrder="order.amount"
            :amount_promotion="amount_promotion"
            :confirmation="true"
            :order="order"
            :account="true"
            @clickToggleReviewEv="clickToggleReview"
        
          />
        
      </div>
	  </div>

   
      
</div>


</template>
        <template v-else>
            <div id="error" v-if="language=='fr'">Vous devez être connecté pour accéder à cette page</div>
            <div id="error" v-else>You must be logged in to access this page</div>
        </template>

</div>

  
  <Footer :language="language" :displayModalMenu="displayModalMenu" />
</template>

<script>
import Footer from '../components/layout/Footer.vue'
import {APIRequest,Woocommerce}  from '@simb2s/senseye-sdk';
import moment from 'moment';
import CheckoutOrderReview from "../components/children/Checkout/CheckoutOrderReviewComponent.vue"

export default {
  name: 'Order',
  components: {
    Footer,
    CheckoutOrderReview
  },
  data() {
    return {
        order_id:null,
        hasOnMeasure:false,
        order:null,
        tracking_number:null,
        loaded:false,
        loggedIn:false
    }
  },
  props:["displayModalMenu","language"],
  async mounted()
  {
    if(localStorage.token_senseye_auth)
    {
        this.loggedIn=true
    }
    this.loaded=true
    try {
      this.order=await APIRequest.detail('orders',this.$route.params.id)
      this.order.delivered_at=moment(this.order.delivered_at, 'DD/MM/YYYY')
     
      this.tracking_number=this.order.tracking_number
     // let post=await Woocommerce.detail('orders',this.order.woo_order)
      // let meta_datas=post.meta_data;
      // for(let i=0;i<meta_datas.length;i++)
      // {
      //     if(meta_datas[i].key=='tracking_number')
      //     {
      //         this.tracking_number = meta_datas[i].value;
      //     }
      // }

      if(this.order.order_items.length)
        {
         
            for(let i=0;i<this.order.order_items.length;i++)
            {
            

                if((this.order.order_items[i].size=='onmeasure') || (this.order.order_items[i].scan!=null))
                {
                 
                    this.hasOnMeasure=true
                }
            }
        }
    } catch (error) {
       this.loaded=false
    }
    
  },
  methods:{
    clickToggleReview() {
        if(jQuery('.order-review #order-review-content').is(':visible'))
        {
            jQuery('.order-review h2.contenumob').removeClass('open');
            jQuery('.order-review #order-review-content').slideUp();
            jQuery('.order-review .subtotals').slideUp();
            jQuery('.order-review  .total').slideUp();
            jQuery('.review-elem').slideUp();
            jQuery('.order-review .message').slideUp();
        }
        else
        {
            jQuery('.order-review h2.contenumob').addClass('open');
            jQuery('.order-review #order-review-content').slideDown();
            jQuery('.order-review .subtotals').slideDown();
            jQuery('.order-review  .total').slideDown();
            jQuery('.order-review .message').slideDown();
            jQuery('.review-elem').slideDown();
        }
    },
  }
  
}
</script>
<style scoped>
#order-number
{
  margin-left:20px;
  color: #000000;
opacity: 0.2;
font-size:15px;
font-weight: 400;
}

@media screen and (max-width:760px)
{

  .left {
    width:100% !important;
  
  }
  .row {
    flex-direction: column;
  }
    .confirm-wrapper .order-review .review-elem
    {
        display: none;
    }
    .step .image::after {
       width:1px !important;
       top:0 !important;
       left:50% !important;
       height:100% !important;
    }
    .step .image {
        display: table-cell;
        width:50px;
        padding-top:30px;
        padding-bottom: 30px;
    }
    .step-name
    {
        vertical-align: middle;
        display: table-cell;
        text-align: left;
    }
    .steps .step 
    {
        display:table;
       
        width:100% !important;
    }
    .steps{

        flex-direction: column;
    }
    body div.payment-confirmation
    {
        width:100% !important;
        max-width:100% !important;
    }
    .confirm-wrapper {
        flex-direction: column;
    }
    .confirm-wrapper .order-review {
        margin-top:20px !important;
        height:auto !important;
        width:90% !important;
        margin-left: 5%;
        max-width:90% !important;
        background: #f1f1f1 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 2px #0000004B;
    }
}

.row {
  display:flex;
  width:100%;

}
.row .right {
  width:50%;
}
.row .left {
  width:50%;
}

.button-white {
    margin-top: 20px;
    color: #fff;
    background: #000;
    display: inline-block;
    padding: 8px 40px;
    border-radius: 40px;
}
.step.active .step-name {
    font-weight: 600;
}
.step-name {
    font-size:16px;
}
.step {
    width:33%;
    text-align: center;
    min-height: 90px;
}

.step .image::after{
    height: 1px;
    content:" ";
    width:100%;
    position: absolute;
    top:50%;
    left:0;
    background:#000;
}
.step.active .image .round {
    background-color: #000;
}
.order-step .content-step {
    padding-left:85px;
    font-size:15px;
   
    padding-top:15px;
    padding-bottom:20px;
}
.step.active .image .round img
{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}
.step .image .round {
    position:absolute;
    z-index: 2;
    left:50%;
    top:50%;
    width:22px;
    height:22px;
    border:1px solid black;
    border-radius: 50%;
    background-color: #fff;
    transform: translate(-50%,-50%);
}
.order-steps-title .content .subtitle {
    font-size:22px;
   
}
.order-steps-title .content .small {
    font-size:16px;
    font-weight: 400;
}
.order-step .content {
    display:inline-block;
    vertical-align: top;
    width: calc(100% - 65px);
}
.order-step.opened .title-step::after
{
    content:"-";
  
    
}
.title-step::after
{
    content:"+";
    position:absolute;
    right:20px;
    top:17px;
    
}
.title-step{
    font-size:16px;
    cursor: pointer;
    position: relative;
}
.order-steps-title .content {
    display:inline-block;
    vertical-align: top;
    width: calc(100% - 61px);
}
.order-steps-title {
  margin-top:30px;
  font-size:28px;
  
  font-weight: 600;
  margin-bottom:30px;
  
}
.order-step:nth-child(2) .title-step{
    border-top:1px solid #757272;
}
.title-step { 
    padding:17px 20px 4px 20px;
    border-bottom:1px solid #757272;
}
.title-step .num {
    width:36px;
    position: relative;
    top:-5px;
    vertical-align: top;
    height:36px;
    padding-top:5px;
    text-align: center;
    border:1px solid black;
    margin-right:25px;
    vertical-align: top;
    display: inline-block;
    border-radius: 50%;
}
.order-steps-title .arrow {
    width:36px;
    position:relative;
    top:2px;
    margin-right:25px;
    vertical-align: top;
    display: inline-block;
}
.message {
    font-size:18px;
}
.step .image
{
    position: relative;
    height:30px;
    margin-bottom:20px;
}
.steps {
    margin-top:30px;
    margin-bottom:30px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.payment-confirmation .title {
    font-size:38px;
    
}
.b {
    font-weight:500;
    
}
a {
  color:#000;
  text-decoration: underline;
}
h2
{
font-size: 18px;
    font-weight: 600;
text-align:left;
padding-bottom: 20px;
border-bottom:1px solid rgba(0,0,0,0.1);
}
.payment-confirmation h2.confirm-title
{
font-size: 20px;
    font-weight: 500;
text-align:left;
}
.confirm-wrapper {
display:flex;
}

body div.payment-confirmation
{
width:45%;
max-width:50%;
padding:5%;

}
.subtotal
{
	clear:both;
	display:block;
	width:100%;
}
.subtotals
{
	padding-top:20px;
	padding-bottom:40px;
	border-top:2px solid #eee;
	border-bottom:2px solid #eee;
}

.subtotal .subtotal-amount
{
	float:right;
}
.subtotal .subtotal-label
{
	float:left;
}
</style>